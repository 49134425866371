import Typography from "@mui/material/Typography";
import API from "utils/API";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import useTracker from "components/CTracker/useTrackerContext";
import CoborrowerInvite from "components/CTracker/StepsDialogs/CoborrowerInvite";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import CTrackerTimelineItem from "components/CTimelineItem";
import CButton from "components/CButton";
import runCreditLambda from "./runCreditLambda";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";
import { DeniedLoanModal } from "./DeniedLoanModal";
import { errorMessages } from "utils/errorMessages";
import { ConfirmFirstLienMortgageModal } from "./ConfirmFirstLienMortgageModal";
import { ConfirmSSNDOB } from "./ConfirmSSNDOBModal";
import Loan from "typedef/Loan";

const CreditVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type, coborrowerInviteIsOpen, setCoborrowerInviteIsOpen } =
    useTracker();
  const loanId = loan?.id;
  const isBorrower = type === "borrower";
  const userId = isBorrower ? loan?.borrowerId : loan?.coborrowerId;
  const [loading, setLoading] = useState(false);
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const flag = isBorrower ? loan?.borrowerFlags : loan?.coborrowerFlags;
  const [openConfirmSSNDOB, setOpenConfirmSSNDOB] = useState(false);

  const prevStep =
    step?.idVerification.status === "success" ||
    (step?.idVerification.status === "analyzing" &&
      (step?.idVerification.needDocReview !== undefined ||
        step?.idVerification.deletedDocRequest ||
        loan?.borrowerSteps?.idVerification.needDocReview !== undefined ||
        loan?.borrowerSteps?.idVerification.deletedDocRequest));
  const status = prevStep
    ? step?.creditVerification?.status ?? "pending"
    : "pending";
  const message = step?.creditVerification?.message;
  const errorCode = step?.creditVerification.errorCode;
  const statement = loan?.borrowerSteps?.creditVerification.statement;

  const needDocsUploadB1 =
    loan?.borrowerSteps?.creditVerification?.needDocReview;
  const needDocsUploadB2 =
    loan?.coborrowerSteps?.creditVerification?.needDocReview;

  const stepName = "creditVerification";
  const processingStatus = step?.creditVerification?.processingStatus;

  const docsToUpload = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName);
  }, [loan]);

  const handleButtonClick = async () => {
    setLoading(true);
    if (loanId && userId) {
      await runCreditLambda(loanId, userId);
      setLoading(false);
    }
  };

  return (
    <CTrackerTimelineItem
      processingStatus={processingStatus}
      status={status}
      title="Credit"
      tooltip="Our initial credit check only requires a soft pull. We use Experian to tell us your credit score and a summary of your credit profile. After Lender Disclosures are signed we do a hard pull of the credit."
      {...(isBorrower &&
        errorCode === "D001" &&
        !loan?.coborrowerInvite &&
        status !== "success" && {
          action: {
            message: "Add Co-Borrower",
            onClick: () => setCoborrowerInviteIsOpen(true),
          },
        })}
    >
      <Box>
        <Typography variant="h6" flex={1} color={[trackerStatusColor[status]]}>
          {status === "analyzing" &&
            (needDocsUploadB1 === false || needDocsUploadB2 === false ? (
              <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
            ) : (
              message ?? "Analyzing"
            ))}

          {status === "failed" && (message ?? "")}
          {status === "failed" && statement && <p>Statement: {statement}</p>}
          {status === "success" && (message ?? "")}
        </Typography>
        {flag?.creditVerification?.reasonCode === "N001" &&
          !message?.includes("Unfortunately your") &&
          prevStep &&
          step?.creditVerification?.status !== "success" && (
            <CButton
              variant="contained"
              onClick={() => setOpenConfirmSSNDOB(true)}
              disabled={
                step?.idVerification.status === "analyzing" ||
                step?.creditVerification.status === "analyzing" ||
                loading
              }
              sx={{
                textTransform: "inherit",
                fontSize: 18,
                fontWeight: 500,
                marginBottom: 3,
                marginTop: 3,
              }}
              name="confirm-ssn-dob"
            >
              Confirm SSN/DOB
            </CButton>
          )}

        {(flag?.creditVerification?.reasonCode === "B001" ||
          flag?.creditVerification?.reasonCode === "F001") &&
          !message?.includes("Unfortunately your") &&
          prevStep && (
            <CButton
              variant="contained"
              onClick={handleButtonClick}
              disabled={loading}
              sx={{
                textTransform: "inherit",
                fontSize: 18,
                fontWeight: 500,
                marginBottom: 3,
                marginTop: 3,
              }}
              name="creditVerification-retryNow"
            >
              Retry Now
            </CButton>
          )}
      </Box>
      <CoborrowerInvite
        open={coborrowerInviteIsOpen}
        onClose={async () => {
          setCoborrowerInviteIsOpen(false);
          await API.post({
            url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
            data: {
              creditVerificationCoborrowerOffered: true,
            },
          });
        }}
      />

      <ConfirmSSNDOB
        loan={loan as Loan}
        handleOnClose={() => setOpenConfirmSSNDOB(false)}
        open={openConfirmSSNDOB}
      />

      <DeniedLoanModal
        open={
          step?.creditVerification?.adminError ===
          errorMessages.creditVerification.adminError
        }
        message={message ?? ""}
      />
      <ConfirmFirstLienMortgageModal
        loan={loan}
        open={
          step?.creditVerification?.messageToAdmin ===
          "Waiting for the borrower to confirm if it is a first lien mortgage."
        }
        isBorrower={isBorrower}
        message={message ?? ""}
        docsToUpload={docsToUpload}
      />
    </CTrackerTimelineItem>
  );
};

export default CreditVerification;
