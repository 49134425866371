import Avatar from "@mui/material/Avatar";
import { Box, Typography, useTheme } from "@mui/material";

interface Prop {
  nmls: number;
  company?: string;
  lastName: string;
  firstName: string;
  companyNMLS?: string;
  profilePicture?: string;
  avatar?: string;
  brokerId?: string;
  phone?: string;
  email?: string;
  title?: string;
  subtitle?: string;
  isScreenAfterMLORegister?: boolean;
}

export default function LoanOfficerInformation({
  nmls,
  company,
  lastName,
  firstName,
  profilePicture,
  companyNMLS,
  avatar,
  brokerId,
  phone,
  email,
  title,
  subtitle,
  isScreenAfterMLORegister = false,
}: Prop) {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      mb={3}
      py={5}
      px={7}
      position="relative"
      justifyContent={"space-between"}
      alignItems={{ xs: "center", sm: "flex-start" }}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Box
        width={{ sm: "30%", xs: undefined }}
        mr={2}
        position="relative"
        flexDirection={"column"}
        display={"flex"}
      >
        <Box
          width={105}
          height={105}
          display={"flex"}
          borderRadius={90}
          alignSelf={"center"}
          bgcolor={theme.palette.primary.main}
          justifyContent={"center"}
        >
          <Avatar
            src={avatar ?? profilePicture}
            alt="Img avatar"
            sx={{
              padding: 0,
              width: 100,
              height: 100,
              alignSelf: "center !important",
            }}
          />
        </Box>
        <Typography
          mt={1}
          variant="h6"
          color={theme.palette.primary.main}
          alignSelf={"center"}
          textAlign="center"
          fontFamily={"Pro Display Semibold"}
        >
          {firstName} {lastName}
        </Typography>
        <Typography
          lineHeight={1}
          variant="subtitle1"
          color={theme.palette.text.primary}
          alignSelf={"center"}
          textAlign="center"
          fontFamily={"Pro Display Semibold"}
        >
          NMLS ID# {nmls}
        </Typography>
      </Box>
      <Box py={{ xs: 0, sm: 2 }} mt={{ xs: 3, sm: 0 }} flex={1}>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          textAlign={"left"}
        >
          <span style={{ fontFamily: "Pro Display Semibold" }}>{title} </span>
          {subtitle}
          <br />
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.primary.main}
          alignSelf={"center"}
          textAlign="left"
          fontFamily={"Pro Display Semibold"}
        >
          {company}
          <br />
          NMLS ID # {companyNMLS}
        </Typography>
        {email && (
          <>
            <a
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              href={`mailto:${email ?? email ?? ""}?Subject=${
                email ?? email ?? ""
              }`}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                alignSelf={"center"}
                textAlign="left"
                fontFamily={"Pro Display Semibold"}
              >
                {email}
              </Typography>
            </a>
          </>
        )}
        {phone && (
          <>
            <a
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              href={`tel:+${phone ?? phone ?? ""}`}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                alignSelf={"center"}
                textAlign="left"
                fontFamily={"Pro Display Semibold"}
              >
                {phone}
              </Typography>
            </a>
          </>
        )}
      </Box>
    </Box>
  );
}
