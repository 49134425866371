import API from "utils/API";
import Broker from "typedef/Broker";
import LoanOfficer from "typedef/LoanOfficer";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import useLoan from "./useLoan";
import Address from "typedef/Address";

type Ocuppancy = "Primary Residence" | "Second Home" | "Investment Property";

export type InviteContextType = {
  borrowerAddres?: Address;
  propertyAddress?: Address;
  useOfProceeds?: string;
  requestedLoanAmount?: string;
  legalFirstNameBorrower?: string;
  legalLastNameBorrower?: string;
  legalMiddleNameBorrower?: string;
  legalSuffixBorrower?: string;
  startDateBorrower?: string;
  totalAnnualIncomeBorrower?: string;
  loanId?: string;
  phoneBorrower?: string;
  confirmEmailAddressBorrower?: string;
  employerNameBorrower?: string;
  estimatedHomeValue?: string;
  code?: string;
  phone?: string;
  email?: string;
  broker?: Broker;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  addressLine?: string;
  suffix?: string;
  loanOfficerId?: string;
  borrowerFullName?: string;
  loanOfficer?: LoanOfficer;
  address?: Address;
  creditScore?: string;
  occupancy?: Ocuppancy;
  employmentType?: string;
  maritalStatus?: string;
  homeValue?: string;
  requestedNewLoan?: string;
  annualIncome?: string;
  currentLoanBalance?: string;
  phoneNumber?: string;
  useProceeds?: string;
  inviteType?: string;
  employerNameCoborrower?: string;
  startDateCoborrower?: string;
  dateOfBirth?: string;
  socialSecurityNumber?: string;
  body?: {
    avmHomeValue?: string;
    estimatedHomeValue?: string;
    completedByBorrower?: boolean;
    ssnCoborrower?: string;
    dateOfBirthCoborrower?: string;
  };
  manualBorrowerAddress?: Address;
  customAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
  customBorrowerAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
  customCoborrowerAddress?: {
    city: string;
    secondary?: string;
    state: string;
    street_name: string;
    street_number: string;
    zipcode: string;
    street_suffix: string;
  };
};

export const InviteContext = React.createContext<InviteContextType | undefined>(
  {},
);

export const useInvite = () => {
  return useContext(InviteContext);
};

export const InviteCustomerContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [invite, setInvite] = useState<InviteContextType>();
  const loan = useLoan();
  const { user } = useUser();
  const error = 400;
  const navigate = useNavigate();
  const brokerName = params["brokerName"];
  const loName = params["loName"];
  const wholesaleEntity = params["wholesaleEntity"];
  const correctEntity =
    wholesaleEntity === "remn" ||
    wholesaleEntity === "hb" ||
    wholesaleEntity === "invite"
      ? wholesaleEntity
      : undefined;
  const code = searchParams.get("code");
  const typeUser = loan
    ? loan?.coborrowerInvite && user && loan?.coborrowerInviteCode === user.id
      ? "coborrower"
      : "borrower"
    : location.pathname.endsWith("/signup")
      ? "borrower"
      : "coborrower";
  useEffect(() => {
    const apiCallParams =
      !code && !brokerName && !loName && loan && loan.loanOfficer
        ? `/get-invite/borrower?loanOfficerId=${loan.loanOfficer.id}`
        : !code && brokerName && loName && !loan?.loanOfficer && wholesaleEntity
          ? `/get-invite/borrower?brokerName=${brokerName}&loName=${loName}&wholesaleEntity=${correctEntity}`
          : code && typeUser
            ? `/get-invite/${typeUser}?code=${code}`
            : undefined;

    if (apiCallParams)
      API.get<InviteContextType>(apiCallParams).then((res) => {
        if ("error" in res) {
          console.error(res);
          if (!loan?.coborrowerInviteCode && res.status === error) {
            navigate("/login");
          }
        } else {
          setInvite(res.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerName, code, loName, loan, typeUser, navigate, error]);

  return (
    <InviteContext.Provider value={invite}>{children}</InviteContext.Provider>
  );
};

export default useInvite;
