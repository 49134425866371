import { Fragment } from "react";
import CButton from "components/CButton";
import CTermsDialog from "components/CTermsDialog";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import useRegisterFormCoborrower from "./useRegisterFormCoborrower";
import {
  Box,
  Link,
  List,
  Checkbox,
  MenuItem,
  ListItem,
  Container,
  Typography,
  useTheme,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import CNumberField from "components/CNumberField";
import CPasswordField from "components/CPasswordField";
import { passwordMsgHelper, regexPassword } from "CONST";
import { useInvite } from "context/Invite/useInviteContext";
import CVerificationDialog from "components/CVerificationDialog";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CCommunicationsDialog from "components/CCommunicationsDialog";
import LoanOfficerInformation from "components/LoanOfficerInformation";
import moment from "moment";
import CLoading from "components/CLoading";
import parseMoney from "utils/parseMoney";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import {
  DemographicOptions,
  InnerPropsDemographicForm,
} from "components/CTracker/Steps/DemographicsVerification/DemographicDataDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CoborrowerRegisterForm = () => {
  const user = useUser();
  const theme = useTheme();
  const invite = useInvite();
  const form = useRegisterFormCoborrower(invite);
  const { privateLabel } = usePrivateLabel();

  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.lenderName;
  const isFullEmployed =
    form.watch("employmentType") === "Full Time Employed/ W2";

  const organizationName = privateLabel
    ? privateLabel.lenderName
    : `NFTYDoor's`;

  if (!invite) return <CLoading />;

  const isFormAddressDataValid =
    form?.addressData && Object?.keys(form?.addressData)?.length > 0
      ? form?.addressData
      : null;
  const isInviteAddressValid =
    invite?.address && Object?.keys(invite?.address)?.length > 0
      ? invite?.address
      : null;

  const address = isFormAddressDataValid ?? isInviteAddressValid ?? null;

  const disabledField = false;

  return (
    <Fragment>
      <Container maxWidth="md">
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3.2rem", md: "5.2rem" }}
          >
            <span style={{ color: theme.palette.primary.main }}>
              Hello {form.watch("firstName")}.
            </span>
            <br /> Register here.
          </Typography>
          <Typography
            mb={2}
            display={"flex"}
            variant="subtitle1"
            px={{ xs: 2, md: 0 }}
            justifyContent={"center"}
          >
            Please complete the steps below to begin the co-borrower process for
            the loan requested on {invite?.addressLine}
          </Typography>
        </Box>
        {invite?.loanOfficer?.firstName && (
          <LoanOfficerInformation
            nmls={invite?.loanOfficer?.NMLSNumber}
            company={invite.broker?.lenderName ?? privateLabel?.lenderName}
            lastName={invite?.loanOfficer.lastName}
            avatar={invite.loanOfficer.avatar}
            companyNMLS={
              invite.broker?.NMLScompany ?? privateLabel?.NMLScompany
            }
            firstName={invite.loanOfficer?.firstName}
            brokerId={invite.broker?.id ?? privateLabel?.id}
            title={"WELCOME! I'm glad you're here!"}
            subtitle={`Interested in accessing your home equity? We now offer Home Equity
          Loans through ${organizationName} digital lending platform. Simply fill out the items below and see your prequalification results instantly. There is no obligation or credit check at this stage of the process, only if you choose to accept and move forward!`}
          />
        )}
        <Box
          borderRadius={10}
          bgcolor={theme.palette.background.paper}
          sx={{ scrollbarWidth: "none" }}
        >
          <Box px={{ xs: 2, md: 6 }} py={{ xs: 4, md: 5 }}>
            <form onSubmit={form.onSubmit}>
              <CTextField
                error={
                  !invite?.borrowerFullName
                    ? "Primary Borrower is required."
                    : undefined
                }
                fullWidth
                type="text"
                value={invite?.borrowerFullName}
                label="Primary Borrower"
                placeholder="John Doe"
                disabled
                sx={{ marginBottom: 3 }}
              />

              <CTextField
                error={form.formState.errors.firstName?.message}
                fullWidth
                type="text"
                label="Legal First Name"
                placeholder="John"
                disabled={disabledField}
                sx={{ marginBottom: 3 }}
                {...form.register("firstName", {
                  required: {
                    value: true,
                    message: "First Name is required.",
                  },
                  minLength: {
                    value: 2,
                    message:
                      "The First Name must be at least 2 characters long.",
                  },
                  maxLength: {
                    value: 120,
                    message:
                      "The First Name cannot be longer than 120 characters.",
                  },
                  pattern: {
                    value:
                      /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
                    message: "The First Name format is incorrect.",
                  },
                })}
                defaultValue={invite?.firstName}
              />

              <CTextField
                fullWidth
                type="text"
                label="Legal Last Name"
                placeholder="Doe"
                sx={{ marginBottom: 3 }}
                disabled={disabledField}
                error={form.formState.errors.lastName?.message}
                {...form.register("lastName", {
                  required: {
                    value: true,
                    message: "Last Name is required.",
                  },
                  minLength: {
                    value: 2,
                    message:
                      "The Last Name must be at least 2 characters long.",
                  },
                  maxLength: {
                    value: 120,
                    message:
                      "The Last Name cannot be longer than 120 characters.",
                  },
                  pattern: {
                    value: /^[a-zA-Z]{2,254}[ a-zA-Z ]/i,
                    message: "The Last Name format is incorrect.",
                  },
                })}
                defaultValue={invite?.lastName}
              />

              <CNumberField
                fullWidth
                label="Phone Number"
                placeholder="+1(123)-123-1234"
                format={
                  process.env.REACT_APP_ENV === "prod"
                    ? "+1###############"
                    : "+###############"
                }
                allowEmptyFormatting
                error={form.formState.errors.phoneNumber?.message}
                type="tel"
                sx={{ marginBottom: 3 }}
                {...form.register("phoneNumber", {
                  required: "This field is required",
                })}
                defaultValue={invite?.phoneNumber}
                disabled={disabledField}
              />

              <CNumberField
                fullWidth
                label="Co-Borrower's Annual Income"
                placeholder="$85,000"
                sx={{ mb: 3 }}
                isNumericString
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                disabled={disabledField}
                prefix="$"
                error={form.formState.errors.annualIncome?.message}
                {...form.register("annualIncome", {
                  required: "This field is required",
                  validate: (newValue) =>
                    newValue.replace("$", "").length < 6
                      ? "You must enter a number of at least 5 digits"
                      : undefined,
                })}
                defaultValue={
                  invite?.annualIncome
                    ? parseMoney(invite?.annualIncome)
                    : undefined
                }
              />
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-start"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                  <CTextField
                    fullWidth
                    select
                    disabled={disabledField}
                    value={form.watch("employmentType")}
                    label="Employment Type"
                    error={form.formState.errors.employmentType?.message}
                    sx={{ marginBottom: 3 }}
                    {...form.register("employmentType", {
                      required: "Employment Type is required",
                    })}
                    defaultValue={invite?.employmentType}
                  >
                    <MenuItem value="Full Time Employed/ W2">
                      Full Time Employed (W2)
                    </MenuItem>
                    <MenuItem value="Self Employed">
                      Self-employed, Fixed income, or other
                    </MenuItem>
                  </CTextField>
                </Box>
                <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                  <CTextField
                    fullWidth
                    select
                    disabled={disabledField}
                    value={form.watch("maritalStatus")}
                    label="Marital Status"
                    error={form.formState.errors.maritalStatus?.message}
                    sx={{ marginBottom: 3 }}
                    {...form.register("maritalStatus", {
                      required: "Marital Status is required",
                    })}
                    defaultValue={invite?.maritalStatus}
                  >
                    <MenuItem value="Unmarried">Unmarried</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Separated">Separated</MenuItem>
                  </CTextField>
                </Box>
              </Box>

              {isFullEmployed && (
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <CTextField
                      fullWidth
                      type="text"
                      disabled={disabledField}
                      label="Employer Name Coborrower"
                      placeholder={privateLabel?.lenderName}
                      error={
                        form.formState.errors.employerNameCoborrower?.message
                      }
                      {...form.register("employerNameCoborrower", {
                        required: {
                          value: true,
                          message: "Last Name is required.",
                        },
                        minLength: {
                          value: 2,
                          message:
                            "The Last Name must be at least 2 characters long.",
                        },
                        maxLength: {
                          value: 120,
                          message:
                            "The Last Name cannot be longer than 120 characters.",
                        },
                      })}
                      defaultValue={invite?.employerNameCoborrower}
                    />
                  </Box>
                  <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                    <CTextField
                      fullWidth
                      type="date"
                      disabled={disabledField}
                      label="Start Date"
                      InputProps={{
                        inputProps: {
                          min: "1908-02-05",
                          max: moment()
                            .subtract(0, "year")
                            .format("YYYY-MM-DD"),
                        },
                      }}
                      error={form.formState.errors.startDateCoborrower?.message}
                      {...form.register("startDateCoborrower", {
                        required: "This field is required",
                      })}
                      sx={{ marginBottom: 3 }}
                      defaultValue={invite?.startDateCoborrower}
                    />
                  </Box>
                </Box>
              )}

              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-start"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                  <CNumberField
                    fullWidth
                    label="Social Security Number"
                    type="text"
                    format={"###-##-####"}
                    placeholder="XXX-XX-XXXX"
                    sx={{ marginBottom: 3 }}
                    error={
                      disabledField
                        ? undefined
                        : form.formState.errors.socialSecurityNumber?.message
                    }
                    {...form.register("socialSecurityNumber", {
                      required: "This field is required",
                      validate: (newValue) =>
                        newValue.replace(/ /g, "").length < 11 && !disabledField
                          ? "This field is required"
                          : undefined,
                    })}
                    defaultValue={
                      invite?.socialSecurityNumber ??
                      invite?.body?.ssnCoborrower
                    }
                    disabled={disabledField}
                  />
                </Box>
                <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                  <CTextField
                    fullWidth
                    type="date"
                    label="Date of birth"
                    disabled={disabledField}
                    InputProps={{
                      inputProps: {
                        min: "1908-02-05",
                        max: moment().subtract(18, "year").format("YYYY-MM-DD"),
                      },
                    }}
                    error={form.formState.errors.dateOfBirth?.message}
                    {...form.register("dateOfBirth", {
                      required: "This field is required",
                    })}
                    sx={{ marginBottom: 3 }}
                    defaultValue={
                      invite?.dateOfBirth ?? invite?.body?.dateOfBirthCoborrower
                    }
                  />
                </Box>
              </Box>

              <CAddressAutocomplete
                label="Mailing Address"
                placeholder="Please the enter the address of where you live."
                address={address}
                setAddress={(newAddress) => {
                  form.clearErrors("propertyAddress");
                  if (!newAddress) {
                    return form.setAddressData(undefined);
                  } else {
                    form?.setAddressData(newAddress);
                  }
                }}
                sx={{ marginBottom: 3 }}
                error={form?.formState?.errors?.propertyAddress?.message}
                disabled={disabledField}
              />
              {form?.addressData?.street_line ===
                "My address is not listed" && (
                <>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                    gap={1}
                  >
                    <Box width={{ xs: "100%", md: "50%" }}>
                      <CTextField
                        label="Street Address"
                        placeholder="Street address"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.street_line
                            ?.message
                        }
                        {...form.register("manualAddress.street_line", {
                          required: "This field is required",
                          maxLength: {
                            value: 45,
                            message:
                              "The street name must be less than 45 letters or numbers.",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="Apt/Suite"
                        placeholder="Unit #"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        {...form.register("manualAddress.secondary")}
                      />
                    </Box>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                    gap={1}
                  >
                    <Box width={{ xs: "100%", md: "50%" }}>
                      <CTextField
                        label="City"
                        placeholder="City"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.city?.message
                        }
                        {...form.register("manualAddress.city", {
                          required: "This field is required",
                          maxLength: {
                            value: 45,
                            message: "The city must be less than 45 letters.",
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "City can only contain letters and spaces",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="State"
                        placeholder="State"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.state?.message
                        }
                        {...form.register("manualAddress.state", {
                          required: "This field is required",
                          minLength: {
                            value: 2,
                            message:
                              "This field must be exactly 2 characters long.",
                          },
                          maxLength: {
                            value: 2,
                            message:
                              "This field must be exactly 2 characters long.",
                          },
                          pattern: {
                            value: /^[A-Z]+$/,
                            message:
                              "This field must contain only uppercase letters",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="Zipcode"
                        placeholder="Zipcode"
                        fullWidth
                        autoComplete="off"
                        defaultValue={undefined}
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.zipcode
                            ?.message
                        }
                        {...form.register("manualAddress.zipcode", {
                          required: "This field is required",
                          minLength: {
                            value: 5,
                            message: "Zipcode must be exactly 5 digits.",
                          },
                          maxLength: {
                            value: 5,
                            message: "Zipcode must be exactly 5 digits.",
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: "Zipcode can only contain numbers",
                          },
                        })}
                      />
                    </Box>
                  </Box>
                </>
              )}

              <CPasswordField
                label="Create Password"
                {...form.register("password", {
                  required: {
                    value: true,
                    message: "Password is required.",
                  },
                  minLength: {
                    value: 9,
                    message: "The password must be at least 9 characters long.",
                  },
                  maxLength: {
                    value: 100,
                    message: "The password must be less than 100 characters.",
                  },
                  pattern: {
                    value: regexPassword,
                    message: passwordMsgHelper,
                  },
                })}
                error={form.formState.errors.password?.message}
                helperText={passwordMsgHelper}
                placeholder="Type in here"
                fullWidth
              />
              <Accordion
                sx={{
                  marginTop: "40px",
                  marginBottom: "10px",
                  boxShadow: "none",
                }}
                expanded={form.isAccordionOpen}
              >
                <AccordionSummary
                  onClick={form.handleAccordion}
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={"1.5rem"}>Demographics</Typography>
                    <Typography sx={{ marginLeft: "5px", color: "red" }}>
                      (required)
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 1 }}>
                  <DemographicOptions
                    form={form as unknown as InnerPropsDemographicForm}
                  />
                </AccordionDetails>
              </Accordion>
              <List
                disablePadding
                sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
              >
                <ListItem disableGutters alignItems="flex-start" sx={{ mt: 1 }}>
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("agree", !form.getValues().agree)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                  >
                    I agree to the{" "}
                    {privateLabel?.isNFTYDoor ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          padding: 0,
                          minWidth: "auto",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "none",
                          },
                        }}
                        onClick={() => {
                          form.setOpenedDialog("terms");
                        }}
                      >
                        Terms of Service
                      </Button>
                    ) : (
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy?.privacyTerms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </Link>
                    )}{" "}
                    and{" "}
                    <Link
                      color={theme.palette.primary.main}
                      textAlign={"justify"}
                      sx={{ cursor: "pointer", textDecoration: "none" }}
                      href={privateLabel?.footerPolicy.privacyPolicyLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </ListItem>
                <ListItem disableGutters alignItems="flex-start">
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("certify", !form.getValues().certify)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                    textAlign={"justify"}
                  >
                    I certify that by checking this box I have read{" "}
                    {privateLabel?.isNFTYDoor ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          padding: 0,
                          minWidth: "auto",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "none",
                          },
                        }}
                        onClick={() => {
                          form.setOpenedDialog("communications");
                        }}
                      >
                        NFTYDoor's Electronic Communications Policy
                      </Button>
                    ) : (
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy?.privacyPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {privateLabel?.lenderName} Electronic Communications
                        Policy
                      </Link>
                    )}
                    , and consent to receive all legally required notices and
                    disclosures and other communications (“Communications”) from
                    {privateLabel
                      ? privateLabel.lenderName
                      : "NFTYDoor LLC"}{" "}
                    electronically and not on paper. I also confirm I am able to
                    access, view, retain and print all of the Communications{" "}
                    {privateLabel ? privateLabel.lenderName : "NFTYDoor LLC"}{" "}
                    provides me in electronic form.
                  </Typography>
                </ListItem>
                <ListItem disableGutters alignItems="flex-start" sx={{ mt: 1 }}>
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("consent", !form.getValues().consent)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                    textAlign={"justify"}
                  >
                    By clicking <b>"I Agree"</b>, I am providing ‘written
                    instructions’ to <b>{lenderName}</b> under the Fair Credit
                    Reporting Act authorizing <b>{lenderName}</b> to obtain
                    information from my personal credit profile or other
                    information related to my employment and income information.
                  </Typography>
                </ListItem>
              </List>

              <CButton
                fullWidth
                type="submit"
                variant="contained"
                loading={form.formState.isSubmitting || user.isGettingLoggedIn}
                disabled={
                  !(
                    form.watch("agree") &&
                    form.watch("certify") &&
                    form.watch("consent")
                  )
                }
                sx={{ mt: 4, mb: 2, fontSize: 18, textTransform: "inherit" }}
              >
                I Agree
              </CButton>
            </form>
          </Box>
        </Box>
      </Container>
      <CVerificationDialog
        phoneNumber={form.watch("phoneNumber")}
        open={form.openedDialog === "verify"}
        email={invite?.email?.trim()}
        onConfirmed={() => {
          form.setOpenedDialog(undefined);
          user.login(invite?.email as string, form.watch("password"));
        }}
      />
      <CDialog
        icon="warning"
        description={form.emailAlreadyUseOnPL}
        open={form.openedDialog === "EMAIL_IN_USE_ON_PL"}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            user.login(invite?.email as string, form.watch("password"));
            form.setOpenedDialog(undefined);
          }}
        >
          Continue
        </CButton>
      </CDialog>
      <CDialog
        icon="cancel"
        description={form.errorMessage}
        open={form.openedDialog === "error"}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => form.setOpenedDialog(undefined)}
        >
          Accept
        </CButton>
      </CDialog>
      <CTermsDialog
        open={form.openedDialog === "terms"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
      <CCommunicationsDialog
        open={form.openedDialog === "communications"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
    </Fragment>
  );
};

export default CoborrowerRegisterForm;
