import API from "utils/API";
import { useEffect, useMemo, useState } from "react";
import Address from "typedef/Address";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useParseToSubmit from "./useParseToSubmit";
import parseMoney from "utils/parseMoney";
import useCalculateMaxLoan from "./useCalculateMaxLoan";
import PricingEngine from "typedef/PricingEngine";
import { passwordMsgHelper, regexPassword } from "CONST";
import statesList from "utils/statesList";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { getUseOfProceedsAdjuster } from "utils/getUseOfProceedsAdjuster";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import moment from "moment";
import useInvite from "context/Invite/useInviteContext";

type MasterLoanOfficer = {
  sk: string;
  NMLS: number;
  firstName: string;
  lastName: string;
  licenseNumber: string;
};

export type RegisterFormDialogs =
  | "terms"
  | "privacy"
  | "verify"
  | "error"
  | "communications"
  | "MAX_OFFER_ERROR"
  | "EMAIL_IN_USE_ON_PL";

type Ocuppancy = "Primary Residence" | "Second Home" | "Investment Property";

type CreditScoreOption = {
  label: string;
  value: string;
};

interface ResponseData {
  code: number;
  message: string;
  userAccountNoNeedVerification: {
    message: string;
  };
}
export type RegisterFormInputs = {
  borrowerAddress?: Address;
  propertyAddress: Address;
  PLMasterLoanOfficer?: MasterLoanOfficer;
  pricingEngineId?: string;
  totalAnnualIncomeBorrower: string;
  creditScoreRange: string;
  confirmEmailAddressBorrower: string;
  legalFirstNameBorrower: string;
  legalLastNameBorrower: string;
  phoneBorrower: string;
  maritalStatusBorrower: string;
  databaseIdPersona: string;
  reportIdPersona: string;
  inviteCode?: string;
  sourceId?: "fortuna" | "ladu";
  occupancyType: Ocuppancy;
  currentLoanBalance: string;
  requestedLoanAmount: string;
  useOfProceeds: string;
  employmentTypeBorrower: string;
  estimatedHomeValue: string;
  hasAcceptTerms: boolean;
  hasAcceptCertify: boolean;
  agreeNotifications: boolean;
  completedByBorrower: boolean;
  legalSuffixBorrower: string;
  employerNameBorrower: string;
  startDateBorrower: string;
  password: string;
  manualAddress: Address & { street_number: string; street_suffix: string };
  manualBorrowerAddress: Address & {
    street_number: string;
    street_suffix: string;
  };
};

const getIndexCreditScore = (
  pricingEngine: PricingEngine,
  creditScore: number,
) => {
  return pricingEngine?.rangesCreditScore?.findIndex(
    (item) => creditScore >= item.min && creditScore <= item.max,
  );
};

const getMarginRate = ({
  pricingEngine,
  cltv,
  creditScore,
}: {
  pricingEngine: PricingEngine;
  cltv: number;
  creditScore: number;
}) => {
  const indexCS = getIndexCreditScore(pricingEngine, creditScore);

  const indexCltv = pricingEngine?.rangesCltv?.findIndex((item) => {
    if (item.min === 0) {
      return (
        Number(cltv.toFixed(2)) >= item.min &&
        Number(cltv.toFixed(2)) <= item.max
      );
    } else {
      return (
        Number(cltv.toFixed(2)) > item.min &&
        Number(cltv.toFixed(2)) <= item.max
      );
    }
  });

  let marginRate = null;
  const margin = pricingEngine?.margin;
  if (indexCS !== -1 && indexCltv !== -1) {
    marginRate = margin?.[indexCS][indexCltv];
  }

  return marginRate;
};

function useYupValidationSchema(pricingEngine: PricingEngine) {
  return useMemo(() => {
    return yup.object().shape({
      propertyAddress: yup
        .mixed()
        .required("Sub property address is required."),
      manualAddress: yup.object().when("propertyAddress", {
        is: (value: Address) =>
          value?.street_line === "My address is not listed",
        then: yup.object().shape({
          street_line: yup
            .string()
            .required("Street name is required")
            .max(
              45,
              "The street name must be less than 45 letters or numbers.",
            ),
          street_number: yup
            .string()
            .required("Street number is required")
            .max(6, "The street number must be less than 6 digits")
            .matches(
              /^\d+$/,
              "The street number must be a number and cannot contain special characters or spaces",
            )
            .trim(),
          street_suffix: yup
            .string()
            .required("Street suffix is required")
            .max(6, "The street suffix must be less than 6 letters.")
            .matches(
              /^[a-zA-Z]+$/,
              "The street suffix must only contain letters and cannot contain numbers or special characters",
            ),
          secondary: yup.string(),
          city: yup
            .string()
            .required("City is required")
            .max(45, "The city must be less than 45 letters.")
            .matches(
              /^[A-Za-z\s]+$/,
              "City can only contain letters and spaces",
            ),
          zipcode: yup
            .string()
            .required("Zipcode is required")
            .length(5, "The zipcode must have 5 digits.")
            .matches(/^\d+$/, "Zipcode can only contain numbers"),
          state: yup
            .string()
            .required("State is required")
            .max(2, "Must be abbreviated (VA, TX, FL, etc)")
            .min(2, "Must be abbreviated (VA, TX, FL, etc)")
            .test("length", "State must be abbreviated", (value) => {
              if (!value) return true;
              else return value?.length === 2;
            })
            .test("list", "State is not valid", (value) => {
              if (!value) return true;
              else return statesList.includes(value);
            }),
        }),
        otherwise: yup.object().optional(),
      }),
      borrowerAddress: yup.mixed().when(
        ["occupancyType", "propertyAddress"],
        //@ts-ignore
        (occupancyType: Ocuppancy, propertyAddress: Address, schema) => {
          if (occupancyType !== "Primary Residence")
            return schema
              .required("Primary home address is required")
              .test(
                "sakeAddress",
                "Sub property address and Primary home address cannot be the same.",
                (borrowerValue: Address) => {
                  return borrowerValue?.street_line ===
                    "My address is not listed"
                    ? true
                    : JSON.stringify(propertyAddress) !==
                        JSON.stringify(borrowerValue);
                },
              );
        },
      ),
      manualBorrowerAddress: yup.object().when("borrowerAddress", {
        is: (borrowerAddress: Address) =>
          borrowerAddress?.street_line === "My address is not listed",
        then: yup.object().shape({
          street_line: yup
            .string()
            .required("Street is required")
            .max(
              45,
              "The street name must be less than 45 letters or numbers.",
            ),
          street_number: yup
            .string()
            .required("Street number is required")
            .max(6, "The street number must be less than 6 digits")
            .matches(
              /^\d+$/,
              "The street number must be a number and cannot contain special characters or spaces",
            )
            .trim(),
          street_suffix: yup
            .string()
            .required("Street suffix is required")
            .max(6, "The street suffix must be less than 6 letters.")
            .matches(
              /^[a-zA-Z]+$/,
              "The street suffix must only contain letters and cannot contain numbers or special characters",
            ),
          secondary: yup.string(),
          city: yup
            .string()
            .required("City is required")
            .max(45, "The city must be less than 45 letters.")
            .matches(
              /^[A-Za-z\s]+$/,
              "City can only contain letters and spaces",
            ),
          zipcode: yup
            .string()
            .required("Zipcode is required")
            .length(5, "The zipcode must have 5 digits.")
            .matches(/^\d+$/, "Zipcode can only contain numbers"),
          state: yup
            .string()
            .required("State is required")
            .max(2, "Must be abbreviated (VA, TX, FL, etc)")
            .min(2, "Must be abbreviated (VA, TX, FL, etc)")
            .test("length", "State must be abbreviated", (value) => {
              if (!value) return true;
              else return value?.length === 2;
            })
            .test("list", "State is not valid", (value) => {
              if (!value) return true;
              else return statesList.includes(value);
            }),
        }),
        otherwise: yup.object().optional(),
      }),
      creditScoreRange: yup.string().required("Credit Score Range is required"),
      useOfProceeds: yup.string().required("Use of proceeds is required."),
      occupancyType: yup.string().required("Occupancy type is required."),

      employmentTypeBorrower: yup
        .string()
        .required("Employment type is required."),
      employerNameBorrower: yup.string().when("employmentTypeBorrower", {
        is: (value: string) => value === "Full Time Employed/ W2",
        then: yup.string().required("Employer name is required."),
        otherwise: yup.string().optional(),
      }),
      startDateBorrower: yup.string().when("employmentTypeBorrower", {
        is: (value: string) => value === "Full Time Employed/ W2",
        then: yup
          .string()
          .required("Start date is required.")
          .test("min date", "Date must be after 1908-02-05", (value) => {
            return new Date(value as string) >= new Date("1908-02-05");
          })
          .test(
            "max date",
            `Date must be before ${moment().format("YYYY-MM-DD")}`,
            (value) => {
              return new Date(value as string) <= new Date();
            },
          ),
        otherwise: yup.string().optional(),
      }),

      confirmEmailAddressBorrower: yup
        .string()
        .email("Email has invalid format.")
        .trim()
        .test("unique-extension", "Email has invalid format.", (value) => {
          const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
          const uniqueExtensions = Array.from(new Set(extensions));
          return extensions.length === uniqueExtensions.length;
        })
        .required("Email is required."),
      phoneBorrower: yup
        .string()
        .required("Phone number is required.")
        .test(
          "minLenght",
          "The phone number must be 11 characters long.",
          (value) => {
            if (!value) return true;
            if (process.env.REACT_APP_ENV === "prod") {
              return value.replaceAll(/ /g, "").length === 12;
            }
            return true;
          },
        ),
      maritalStatusBorrower: yup
        .string()
        .required("Marital status is required."),
      legalFirstNameBorrower: yup
        .string()
        .required("Legal first name is required."),
      legalLastNameBorrower: yup
        .string()
        .required("Legal Last name is required."),
      legalSuffixBorrower: yup.string().optional(),
      estimatedHomeValue: yup
        .string()
        .required("Home value is required.")
        .test("min", "Home value cannot be less than $100,000", (value) => {
          if (!value) return true;
          if (parseMoney(value) < 100_000) return false;
          return true;
        }),
      currentLoanBalance: yup
        .string()
        .required("Current loan balance is required"),
      requestedLoanAmount: yup
        .string()
        .when(["currentLoanBalance"], (currentLoanBalance, schema) => {
          if (parseMoney(currentLoanBalance) === 0) {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMaxFirstLien}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMaxFirstLien
                  );
                },
              );
          } else {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMax}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMax
                  );
                },
              );
          }
        }),
      hasAcceptTerms: yup.boolean().required("Accept terms and conditions."),
      hasAcceptCertify: yup.boolean().required("Accept certify."),
      totalAnnualIncomeBorrower: yup
        .string()
        .min(7, "You must enter a number of at least 5 digits.")
        .required("Annual income is required."),
      agreeNotifications: yup.boolean().required("Agree notifications."),
      password: yup
        .string()
        .required("Password is required.")
        .max(100, "The password must be less than 100 characters.")
        .min(9, "The password must be at least 9 characters long.")
        .matches(regexPassword, passwordMsgHelper),
    });
  }, [pricingEngine]);
}
const useRegisterForm = () => {
  const location = useLocation();
  const [creditScoreOptions, setCreditScoreOptions] = useState<
    CreditScoreOption[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorCode, setErrorCode] = useState<string>();
  const [openedDialog, setOpenedDialog] = useState<RegisterFormDialogs>();
  const [emailAlreadyUseOnPL, setEmailAlreadyUseOnPL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const parseToSubmit = useParseToSubmit();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState<
    { error?: string } | number
  >();
  const [isInvalidStateId, setIsInvalidStateId] = useState<boolean | string>(
    false,
  );
  const { privateLabel } = usePrivateLabel();
  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();
  const [PLMasterLoanOfficer, setPLMasterLoanOfficer] = useState<
    MasterLoanOfficer | undefined
  >(undefined);
  const schema = useYupValidationSchema(pricingEngine as PricingEngine);
  const invite = useInvite();
  const loanOfficerId = invite?.loanOfficerId;
  const navigate = useNavigate();

  const form = useForm<RegisterFormInputs>({
    mode: "onChange",
    defaultValues: {
      propertyAddress: location.state as Address,
      borrowerAddress: undefined,
      PLMasterLoanOfficer: PLMasterLoanOfficer,
      pricingEngineId: pricingEngine?.id,
      creditScoreRange: creditScoreOptions[7]?.value,
      useOfProceeds: "",
      occupancyType: "Primary Residence",
      employmentTypeBorrower: "",
      confirmEmailAddressBorrower: "",
      legalFirstNameBorrower: "",
      phoneBorrower: process.env.REACT_APP_ENV === "prod" ? "+1" : "",
      legalLastNameBorrower: "",
      estimatedHomeValue: "",
      requestedLoanAmount: "",
      hasAcceptTerms: false,
      hasAcceptCertify: false,
      totalAnnualIncomeBorrower: "",
      agreeNotifications: false,
      currentLoanBalance: "",
      completedByBorrower: true,
      legalSuffixBorrower: "",
      employerNameBorrower: "",
      startDateBorrower: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const onSaveUnqualified = (error?: { id: string; message: string }) =>
    form.handleSubmit(async (data) => {
      setLoading(true);

      try {
        const response = await API.post({
          url: `/save-unqualified`,
          data: {
            ...parseToSubmit(data),
            error,
            password: undefined,
          },
        });
        if ("error" in response) {
          setErrorMessage(response.error);
          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR" && privateLabel) {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support:\n\nsupport@nftydoor.com",
          );
          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR") {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support:\n\nsupport@nftydoor.com",
          );
          setOpenedDialog("error");
        }
      } catch (unknownError) {
        setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
      setLoading(false);
    })();

  const requestedLoanAmount = parseMoney(form.watch("requestedLoanAmount"));
  const currentLoanBalance = parseMoney(form.watch("currentLoanBalance"));

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    try {
      const response = await API.post<ResponseData>({
        url: `/register-user/borrowerInviteLink`,
        data: parseToSubmit({
          ...data,
          PLMasterLoanOfficer,
          pricingEngineId: pricingEngine?.id,
        }),
      });

      if ("error" in response) {
        setOpenedDialog(
          response.errorId === "MAX_OFFER_ERROR" ? "MAX_OFFER_ERROR" : "error",
        );
        setErrorMessage(response.error);
        setErrorCode(response.errorId);

        response.errorId !== "MAX_OFFER_ERROR" &&
          onSaveUnqualified(
            response.errorId
              ? {
                  id: response.errorId ?? "",
                  message: response.error ?? "",
                }
              : undefined,
          );
      } else {
        const data = response.data;
        if (data?.userAccountNoNeedVerification?.message) {
          setEmailAlreadyUseOnPL(
            response.data.userAccountNoNeedVerification.message,
          );
          setOpenedDialog("EMAIL_IN_USE_ON_PL");
        } else {
          setOpenedDialog("verify");
          setShowSuccessDialog(true);
          navigate(`/submitted?mloid=${loanOfficerId}`);
        }
      }
    } catch (unknownError) {
      setErrorMessage(String(unknownError));
      console.error(unknownError);
    }
    setLoading(false);
  });

  const homeValue = parseMoney(form.getValues().estimatedHomeValue);
  const cltvValue = useMemo(() => {
    const cltv = (currentLoanBalance + requestedLoanAmount) / homeValue;
    return cltv;
  }, [requestedLoanAmount, homeValue, currentLoanBalance]);

  const propertyAddress = form.watch("propertyAddress");
  const useOfProceedsSelectedValue = form.watch("useOfProceeds");

  const currMaxCreditScoreValue = parseFloat(
    form.getValues()?.creditScoreRange?.includes("-")
      ? form.getValues()?.creditScoreRange?.split(" - ")[1]
      : form.getValues()?.creditScoreRange,
  );

  const creditScoreRange = form.watch("creditScoreRange");

  const { maxLoanAmount: calcLoanAmount, pricingEngine: pricingEngineData } =
    useCalculateMaxLoan(form.watch);

  useEffect(() => {
    const options =
      pricingEngine?.rangesCreditScore?.map((range, index, array) => {
        const fullRange = `${range.min} - ${range.max}`;
        if (index === array.length - 1) {
          return { label: "I don't know", value: String(range?.max) };
        }
        return { label: fullRange, value: fullRange };
      }) ?? [];
    setCreditScoreOptions(options);
  }, [pricingEngine, creditScoreRange]);

  useEffect(() => {
    setMaxLoanAmount(calcLoanAmount);
  }, [calcLoanAmount]);

  useEffect(() => {
    const validateRequestAmount = (
      currentLoanBalance: number,
      propertyAddress: Address,
    ) => {
      if (!requestedLoanAmount) return false;
      if (
        requestedLoanAmount <
          (propertyAddress?.state === "MN" ? 100000 : 25000) ||
        requestedLoanAmount > maxLoanAmount ||
        requestedLoanAmount >
          (currentLoanBalance === 0
            ? pricingEngine?.loanMaxFirstLien ?? 400000
            : pricingEngine?.loanMax ?? 400000)
      )
        return true;
    };

    if (!cltvValue) return;
    setLoading(true);
    const data = {
      initialDrawAmount: requestedLoanAmount,
      cltv: cltvValue,
      creditScore: currMaxCreditScoreValue,
      currentLoanBalance: currentLoanBalance,
      useOfProceeds: useOfProceedsSelectedValue,
      pricingEngineId: pricingEngine?.id,
    };
    const timeOut = setTimeout(() => {
      const validateAmount = validateRequestAmount(
        requestedLoanAmount,
        propertyAddress,
      );

      if (validateAmount === false) {
        setInterestRate({
          error:
            "We cannot provide an offer because FICO is too low. Please archive this application and reapply later",
        });
      } else {
        API.post<{
          monthlyInterestCharge: number;
          interestRate: number;
          marginRate: number;
          primeRate: number;
        }>({
          url: `/get/interestRate`,
          data,
        })
          .then((result) => {
            if ("error" in result) {
              setInterestRate(undefined);
            } else setInterestRate(result?.data?.interestRate);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 1500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [
    cltvValue,
    currMaxCreditScoreValue,
    currentLoanBalance,
    propertyAddress,
    maxLoanAmount,
    requestedLoanAmount,
    useOfProceedsSelectedValue,
    pricingEngine?.loanMax,
    pricingEngine?.loanMaxFirstLien,
    pricingEngine,
  ]);

  const offertOptionsByFico = useMemo(() => {
    if (
      (!requestedLoanAmount || !maxLoanAmount || !currMaxCreditScoreValue) &&
      loading
    )
      return [];
    if (!pricingEngineData) {
      return;
    }

    const indexCreditScore = getIndexCreditScore(
      pricingEngineData,
      currMaxCreditScoreValue,
    );

    const rowsNumber =
      pricingEngineData?.margin?.[indexCreditScore]?.filter((el) => el !== null)
        .length ?? 0;

    const offers: {
      maxLoanAmount: number;
      rate: number | undefined;
    }[] = [];

    for (let index = 0; index < rowsNumber; index++) {
      const currMaxCltv = pricingEngineData?.rangesCltv[index]?.max;

      const newRequestedLoanAmount =
        currMaxCltv * homeValue - currentLoanBalance;

      const marginRate = getMarginRate({
        pricingEngine: pricingEngineData,
        cltv: currMaxCltv,
        creditScore: currMaxCreditScoreValue,
      });

      const loanPurposeValue = getUseOfProceedsAdjuster(
        pricingEngineData?.marginAdjusters?.loanPurpose,
        useOfProceedsSelectedValue,
      );

      let interestRateValue;

      if (pricingEngineData?.primeRate !== undefined)
        if (
          currentLoanBalance === 0 &&
          pricingEngineData?.firstLienDiscount &&
          marginRate &&
          loanPurposeValue
        ) {
          interestRateValue =
            marginRate +
            loanPurposeValue +
            pricingEngineData?.primeRate -
            pricingEngineData?.firstLienDiscount;
        } else {
          interestRateValue =
            (marginRate ?? 0) + pricingEngineData?.primeRate + loanPurposeValue;
        }

      if (newRequestedLoanAmount > requestedLoanAmount) {
        if (currentLoanBalance === 0) {
          if (newRequestedLoanAmount < pricingEngineData?.loanMaxFirstLien) {
            offers.push({
              maxLoanAmount: newRequestedLoanAmount,
              rate: interestRateValue,
            });
          } else if (
            !offers?.some(
              (offer) =>
                offer.maxLoanAmount === pricingEngineData?.loanMaxFirstLien,
            )
          ) {
            offers.push({
              maxLoanAmount: pricingEngineData?.loanMaxFirstLien,
              rate: interestRateValue,
            });
          }
        } else if (newRequestedLoanAmount < pricingEngineData?.loanMax) {
          offers.push({
            maxLoanAmount: newRequestedLoanAmount,
            rate: interestRateValue,
          });
        } else if (
          !offers?.some(
            (offer) => offer.maxLoanAmount === pricingEngineData?.loanMax,
          )
        ) {
          offers.push({
            maxLoanAmount: pricingEngineData?.loanMax,
            rate: interestRateValue,
          });
        }
      }
    }

    return offers;
  }, [
    maxLoanAmount,
    currentLoanBalance,
    homeValue,
    loading,
    pricingEngineData,
    currMaxCreditScoreValue,
    requestedLoanAmount,
    useOfProceedsSelectedValue,
  ]);

  const currentOccupancy = form.watch("occupancyType");
  const currentState =
    form.watch("propertyAddress.state") || "manualAddress.state";

  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(currentOccupancy);
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [currentOccupancy, privateLabel]);

  const currentManualState = form.watch("manualAddress.state");
  const currentPropertyAddress = form.watch("propertyAddress");

  useEffect(() => {
    const currentStreetLine = currentPropertyAddress?.street_line;
    const statesLicensed = privateLabel?.statesLicensed;

    function handleListedAddrees() {
      const currentState = currentPropertyAddress?.state;
      if (currentState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentState.toUpperCase(),
        );

        if (currentState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentState}`,
          );
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    function handleNotListedAddrees() {
      if (currentManualState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentManualState.toUpperCase(),
        );

        if (currentManualState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentManualState}`,
          );
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    if (currentStreetLine !== "My address is not listed") {
      handleListedAddrees();
    } else if (currentStreetLine === "My address is not listed") {
      handleNotListedAddrees();
    } else {
      setIsInvalidStateId(false);
    }
  }, [
    currentManualState,
    currentPropertyAddress?.state,
    currentPropertyAddress?.street_line,
    privateLabel,
  ]);

  return {
    interestRate,
    showSuccessDialog,
    setShowSuccessDialog,
    offertOptionsByFico,
    requestedLoanAmount,
    onSaveUnqualified,
    onSubmit,
    openedDialog,
    errorMessage,
    setOpenedDialog,
    emailAlreadyUseOnPL,
    setErrorMessage,
    errorCode,
    loading,
    propertyState: propertyAddress?.state,
    currentState,
    isInvalidStateId,
    PLMasterLoanOfficer,
    setPLMasterLoanOfficer,
    creditScoreOptions,
    ...form,
  };
};

export default useRegisterForm;
