import Loan from "typedef/Loan";
import useLoan from "./useLoan";
import React, { ReactNode, useContext } from "react";
import { PropertyType } from "typedef/Property";
import useDocuments, { DocumentsHook } from "./Documents/useDocuments";
import usePayments, { PaymentsHook } from "./PaymentsHistory/usePayments";

export type HomeMonitorContextType = {
  loan?: Loan;
  property?: PropertyType;
  documentsHook?: DocumentsHook;
  paymentHook?: PaymentsHook;
};

const useHomeMonitor = () => {
  return useContext(HomeMonitorContext);
};

export const HomeMonitorContext = React.createContext<HomeMonitorContextType>(
  {},
);

export const HomeMonitorContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { loan } = useLoan();
  const property = loan?.property;
  const documentsHook = useDocuments(loan);
  const paymentHook = usePayments(loan?.id);
  return (
    <HomeMonitorContext.Provider
      value={{ loan, property, documentsHook, paymentHook }}
    >
      {children}
    </HomeMonitorContext.Provider>
  );
};

export default useHomeMonitor;
