import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LoanOfficerInformation from "components/LoanOfficerInformation";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import API from "utils/API";

type Broker = {
  NMLScompany: string;
  acceptedInvitesCount: number;
  accountExecutiveId: string;
  accountManager: string;
  adminId: string;
  agreementOnFile: boolean;
  cityName: string;
  commissionLoan: number;
  contactEndDate: string;
  contactStartDate: string;
  counterPartyId: string;
  email: string;
  foundedCount: number;
  id: string;
  inviteCount: number;
  lenderName: string;
  licenseNumber: string;
  licenseType: string;
  loCount: number;
  logo: string;
  paymentInstructionOnFile: boolean;
  paymentInstructionsOnFile: boolean;
  phone: string;
  pointOfContact: {
    secundaryPhone: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  privateLabelId: string;
  sk: string;
  stateName: string;
  statesLicensed: string[];
  streetLine1: string;
  streetLine2: string;
  w9OnFile: boolean;
  web: string;
  wholesaleEntity: string;
  zip: number;
};

type LoanOfficer = {
  avatar: string;
  NMLSNumber: number;
  acceptedInvitesCount: number;
  brokerId: string;
  brokerSk: string;
  createdAt: string;
  email: string;
  firstName: string;
  foundedLoansCount: number;
  id: string;
  inviteCount: number;
  lastName: string;
  licenseNumber: string;
  licenseType: string;
  privateLabelId: string;
  statesLicensed: string[];
  status: string;
  userRole: string;
  userRoleName: string;
  loanOfficerId: string;
  phone: string;
  urlNameBroker: string;
  urlNameLoanOfficer: string;
};

type APIResponse = {
  broker: Broker;
  loanOfficer: LoanOfficer;
  code: string;
  createdAt: string;
  email: string;
  firstName: string;
  inviteType: string;
  lastName: string;
  loanOfficerId: string;
  phone: string;
  privateLabelId: string;
  status: string;
  urlNameBroker: string;
  urlNameLoanOfficer: string;
};

const ScreenAfterMLORegister = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const mloid = searchParams.get("mloid");
  const [response, setResponse] = useState<APIResponse | undefined>(undefined);
  const { privateLabel } = usePrivateLabel();

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.get<APIResponse>(
        `/get-invite/borrower?loanOfficerId=${mloid}`,
      );

      if ("data" in response) {
        setResponse(response?.data);
      }
    };

    if (mloid) {
      fetchData();
    }
  }, [mloid]);

  return (
    <Fragment>
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
        sx={{ marginTop: "10px" }}
      >
        <Box display={"flex"} py={5}>
          <Typography
            data-cy="thankYouMessage"
            mt={5}
            mb={3}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            pl={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3.5rem", md: "6rem" }}
          >
            Thank you for your submission.{" "}
          </Typography>
        </Box>
        <LoanOfficerInformation
          firstName={response?.loanOfficer?.firstName ?? ""}
          lastName={response?.loanOfficer?.lastName ?? ""}
          nmls={response?.loanOfficer?.NMLSNumber ?? 0}
          avatar={response?.loanOfficer?.avatar}
          company={response?.broker?.lenderName || privateLabel?.lenderName}
          companyNMLS={
            response?.broker?.NMLScompany || privateLabel?.NMLScompany
          }
          email={response?.loanOfficer?.email}
          phone={response?.loanOfficer?.phone}
          title={"Your HELOC submission is under review!"}
          subtitle={`I'll review your inputs and submit the loan after a few calculations! Once complete you will be contacted to register and complete a few quick verifications.`}
          isScreenAfterMLORegister={true}
        />
      </Container>
    </Fragment>
  );
};

export default ScreenAfterMLORegister;
