import { Fragment, useCallback, useEffect, useState } from "react";
import CButton from "components/CButton";
import useRegisterForm from "./useRegisterForm";
import CTermsDialog from "components/CTermsDialog";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import {
  Box,
  Link,
  List,
  Button,
  Checkbox,
  MenuItem,
  ListItem,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import CDialog from "components/CDialog";
import CLoader from "components/CLoader";
import parseMoney from "utils/parseMoney";
import { passwordMsgHelper } from "CONST";
import { useNavigate, useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import CTextField from "components/CTextField";
import HTMLtoolTip from "components/HTMLtoolTip";
import CNumberField from "components/CNumberField";
import CPasswordField from "components/CPasswordField";
import ConfirmValuesDialog from "./ConfirmValuesDialog";
import { useInvite } from "context/Invite/useInviteContext";
import FortunaInformation from "components/FortunaInformation";
import CVerificationDialog from "components/CVerificationDialog";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CCommunicationsDialog from "components/CCommunicationsDialog";
import LoanOfficerInformation from "components/LoanOfficerInformation";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { Occupancy, OccupancyItem } from "typedef/PrivateLabel";
import moment from "moment";
import LaduInformation from "components/LaduInformation";
import blueSageValidateLicense, {
  useDebounce,
} from "screens/BorrowerInviteRegisterForm/blueSageValidateLicense";
import { MasterLoanOfficer } from "screens/BorrowerInviteRegisterForm";

type CreditScoreOption = {
  label: string;
  value: number | string;
};

const Form: React.FC = () => {
  const [creditScoreOptions, setCreditScoreOptions] = useState<
    CreditScoreOption[]
  >([]);
  const user = useUser();
  const theme = useTheme();
  const invite = useInvite();
  const form = useRegisterForm();
  const { setPLMasterLoanOfficer } = form;
  const navigate = useNavigate();
  const { privateLabel } = usePrivateLabel();
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageManualAddress, setErrorMessageManualAddress] =
    useState(false);
  const [loadingBSApi, setLoadingBSApi] = useState(false);
  const [loadingBSApiManualAddress, setLoadingBSApiManualAddress] =
    useState(false);

  const params = useParams();
  const isABroker = params["brokerName"];
  const sourceId = params["sourceId"];
  const control = form.control;
  const occupancy = form.watch("occupancy");
  const addressStreet = form.watch("propertyAddress.street_line");
  const borrowerStreet = form.watch("borrowerAddress.street_line");
  const isFullEmployed =
    form.watch("employmentType") === "Full Time Employed/ W2";
  const showBorrowerAddress = occupancy && occupancy !== "Primary Residence";
  const asTxOcuppancyType = form.getValues().propertyAddress?.state === "TX";
  const occupancyTypes = asTxOcuppancyType
    ? [
        {
          value: "Primary Residence" as Occupancy,
          label: "Primary Residence" as Occupancy,
        } as OccupancyItem,
      ]
    : privateLabel?.occupancyTypesEnabled?.map(
        (value) =>
          ({
            value: value,
            label: value,
          }) as OccupancyItem,
      );
  const disableSubmit =
    !(form.watch("hasAcceptCertify") && form.watch("hasAcceptTerms")) ||
    (form.isInvalidStateId as boolean) ||
    errorMessage ||
    errorMessageManualAddress;

  const { errors } = form.formState;
  const userAlreadyExists = form.errorCode === "EMAIL_IN_USE";
  const disabledField = invite && invite?.inviteType === "one_use";

  const organizationName = privateLabel
    ? privateLabel.lenderName
    : `NFTYDoor's`;

  const streetLine = form.watch("propertyAddress.street_line");
  const pricingEngine = form.pricingEngine;

  const creditScoreWatcher = form.watch("creditScore");

  useEffect(() => {
    const options = pricingEngine?.rangesCreditScore.map(
      (range, index, array) => {
        const fullRange = `${range.min} - ${range.max}`;
        if (index === array.length - 1) {
          return { label: "I don't know", value: range?.max };
        }
        return { label: fullRange, value: fullRange };
      },
    );
    setCreditScoreOptions(options ?? []);
  }, [pricingEngine, creditScoreWatcher]);

  const propertyAddressValue = form.watch("propertyAddress");
  const propertyAddressManualAddress = form.watch("manual_state");
  const debounceManualAddress = useDebounce(propertyAddressManualAddress, 1000);
  const loanOfficerId = invite?.loanOfficerId;

  const getBluesageValidationLicense = useCallback(async () => {
    if (
      !privateLabel?.isNFTYDoor &&
      (propertyAddressValue?.state || debounceManualAddress)
    ) {
      const valueToValidate =
        propertyAddressValue?.state || debounceManualAddress;
      if (loanOfficerId) {
        valueToValidate === propertyAddressValue?.state
          ? setLoadingBSApi(true)
          : setLoadingBSApiManualAddress(true);
        const result = await blueSageValidateLicense(
          valueToValidate?.toUpperCase() as string,
          loanOfficerId,
          privateLabel,
        );

        const isValidMasterLoanOfficerData = (data: MasterLoanOfficer) => {
          return ["sk", "NMLS", "firstName", "lastName", "licenseNumber"].every(
            (key) => key in data,
          );
        };

        if (!privateLabel?.isNFTYDoor && result.data.data) {
          const { id, ...rest } = result.data.data[0];
          if (isValidMasterLoanOfficerData(rest)) {
            setPLMasterLoanOfficer(rest as MasterLoanOfficer);
          }
        }

        if (
          result?.data?.message !== "Valid license" &&
          propertyAddressValue?.state
        ) {
          setErrorMessage(true);
          setErrorMessageManualAddress(false);
          setLoadingBSApi(false);
        } else if (
          result?.data?.message !== "Valid license" &&
          debounceManualAddress
        ) {
          setErrorMessageManualAddress(true);
          setErrorMessage(false);
          setLoadingBSApiManualAddress(false);
        } else {
          setErrorMessageManualAddress(false);
          setErrorMessage(false);
          setLoadingBSApi(false);
          setLoadingBSApiManualAddress(false);
        }
      }
    }
  }, [
    propertyAddressValue?.state,
    debounceManualAddress,
    loanOfficerId,
    setPLMasterLoanOfficer,
    privateLabel,
  ]);

  useEffect(() => {
    getBluesageValidationLicense();
  }, [getBluesageValidationLicense]);
  return (
    <Fragment>
      {isABroker && <CLoader loading={invite ? false : true} />}
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Fragment>
          <Box>
            <Typography
              mt={4}
              mb={5}
              variant="h1"
              lineHeight={1}
              color={theme.palette.text.primary}
              letterSpacing={-0.8}
              px={{ xs: 2, md: 0 }}
              fontSize={{ xs: "3.5rem", md: "5rem" }}
            >
              <span style={{ color: theme.palette.primary.main }}>Hello. </span>{" "}
              Get pre-qualified
              <br />
              for your loan here.
            </Typography>
          </Box>

          {invite?.loanOfficer?.firstName && (
            <LoanOfficerInformation
              nmls={invite?.loanOfficer?.NMLSNumber}
              company={invite.broker?.lenderName ?? privateLabel?.lenderName}
              lastName={invite?.loanOfficer.lastName}
              avatar={invite.loanOfficer.avatar}
              companyNMLS={
                invite.broker?.NMLScompany ?? privateLabel?.NMLScompany
              }
              firstName={invite.loanOfficer?.firstName}
              brokerId={invite.broker?.id ?? privateLabel?.id}
              title={"WELCOME! I'm glad you're here!"}
              subtitle={`Interested in accessing your home equity? We now offer Home Equity
          Loans through ${organizationName} digital lending platform. Simply fill out the items below and see your prequalification results instantly. There is no obligation or credit check at this stage of the process, only if you choose to accept and move forward!`}
            />
          )}
          {sourceId === "ladu" && <LaduInformation />}
          {""}
          {sourceId === "fortuna" && <FortunaInformation />}
          <Box
            borderRadius={10}
            bgcolor={theme.palette.background.paper}
            sx={{ scrollbarWidth: "none" }}
          >
            <Box px={{ xs: 2, md: 6 }} py={{ xs: 4, md: 5 }}>
              {((invite?.loanOfficer?.firstName && privateLabel?.isNFTYDoor) ||
                sourceId) && (
                <Typography
                  pb={4}
                  variant="h6"
                  display={"flex"}
                  alignSelf={"center"}
                  justifyContent={"center"}
                  fontFamily={"Pro Display Light"}
                >
                  Powered by{" "}
                  <span
                    style={{
                      fontFamily: "Pro Display Semibold",
                      marginLeft: 4,
                    }}
                  >
                    <span style={{ color: theme.palette.primary.main }}>
                      NFTY
                    </span>
                    Door.
                  </span>
                </Typography>
              )}
              <form onSubmit={form.onSubmit}>
                <Controller
                  name={"propertyAddress"}
                  control={control}
                  render={({ field }) => (
                    <>
                      {loadingBSApi && (
                        <CircularProgress
                          style={{ width: 16, height: 16, color: "grey" }}
                        />
                      )}
                      <CAddressAutocomplete
                        name="propertyAddressAutocomplete"
                        placeholder="Start typing your home address and please select from the dropdown."
                        address={field.value}
                        setAddress={field.onChange}
                        sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                        label="Subject Property Address"
                        disabled={disabledField}
                        //@ts-ignore
                        error={
                          errorMessage
                            ? "Your loan officer is not licensed in this state, please contact them for next steps"
                            : errors?.propertyAddress?.message
                        }
                        isInvalidStateId={form.isInvalidStateId}
                      />
                    </>
                  )}
                />
                {addressStreet === "My address is not listed" && (
                  <>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"flex-start"}
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={1}
                    >
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_street_number"
                          render={({ field }) => (
                            <CTextField
                              label="Street Number"
                              placeholder="Street number"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_street_number?.message}
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_street_name"
                          render={({ field }) => (
                            <CTextField
                              label="Street Name"
                              placeholder="Street name"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_street_name?.message}
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_street_suffix"
                          render={({ field }) => (
                            <CTextField
                              label="Street Suffix"
                              placeholder="Ln, Rd, St..."
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_street_suffix?.message}
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_apt"
                          render={({ field }) => (
                            <CTextField
                              label="Apt/Suite"
                              placeholder="Unit #"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_apt?.message}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"flex-start"}
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={1}
                    >
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_city"
                          render={({ field }) => (
                            <CTextField
                              label="City"
                              placeholder="City"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_city?.message}
                            />
                          )}
                        />
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_state"
                          render={({ field }) => (
                            <>
                              <CTextField
                                label="State"
                                placeholder="VA, FL, TX..."
                                fullWidth
                                autoComplete="off"
                                disabled={disabledField}
                                sx={{ mb: 3 }}
                                {...field}
                                error={
                                  errorMessageManualAddress
                                    ? "Your loan officer is not licensed in this state, please contact them for next steps"
                                    : errors?.manual_state?.message
                                }
                              />
                              {loadingBSApiManualAddress && (
                                <CircularProgress
                                  style={{
                                    width: 16,
                                    height: 16,
                                    color: "grey",
                                  }}
                                />
                              )}
                            </>
                          )}
                        />
                        {streetLine === "My address is not listed" &&
                          form?.isInvalidStateId &&
                          !errors?.manual_state?.message && (
                            <Typography
                              mt={-2}
                              mb={2}
                              ml={1.5}
                              variant="body1"
                              lineHeight={1}
                              color={theme.palette.error.main}
                              fontSize={{ xs: "0.75rem" }}
                            >
                              {form?.isInvalidStateId}
                            </Typography>
                          )}
                      </Box>
                      <Box width={{ xs: "100%", md: "50%" }}>
                        <Controller
                          control={control}
                          name="manual_zipcode"
                          render={({ field }) => (
                            <CTextField
                              label="Zipcode"
                              placeholder="Zipcode"
                              fullWidth
                              autoComplete="off"
                              disabled={disabledField}
                              sx={{ mb: 3 }}
                              {...field}
                              error={errors?.manual_zipcode?.message}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                <Controller
                  name={"occupancy"}
                  control={control}
                  render={({ field }) => (
                    <CTextField
                      select
                      disabled={disabledField}
                      fullWidth
                      tooltip={
                        <HTMLtoolTip
                          title={
                            <Fragment>
                              <Typography color="inherit">
                                Owner Occupied
                              </Typography>
                              {
                                "I/We will occupy the subject property as my/our principal residence within 60 days after the date of closing as required by, and in compliance with, the terms of the Deed of Trust/Mortgage/Security Instrument relating to the subject property. I/We will continue to occupy the property as my/our principal residence for at least one year after the date of occupancy, unless Lender otherwise agrees in writing or extenuating circumstances exist which are beyond my/our control."
                              }
                              <br />
                              <br />
                              <Typography color="inherit">
                                Occupied as a Second Home
                              </Typography>
                              {
                                "I/We will occupy the subject property as my/our second residence as required by, and in compliance with, the terms of the Deed of Trust/Mortgage/Security Instrument relating to the subject property."
                              }
                              <br />
                              <br />
                              <Typography color="inherit">
                                Investment Property - Will Not Occupy
                              </Typography>
                              {"I/We will not occupy the subject property."}
                            </Fragment>
                          }
                        >
                          <Button />
                        </HTMLtoolTip>
                      }
                      label="Occupancy Type"
                      placeholder="Select"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.occupancy?.message}
                    >
                      {occupancyTypes?.map((item, index) => {
                        return (
                          <MenuItem value={item?.value} key={index}>
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </CTextField>
                  )}
                />
                {showBorrowerAddress && (
                  <>
                    <Controller
                      name="borrowerAddress"
                      control={control}
                      render={({ field }) => (
                        <CAddressAutocomplete
                          {...field}
                          onChange={undefined}
                          placeholder="Please enter the address of the home you currently live in."
                          address={field.value}
                          disabled={disabledField}
                          setAddress={field.onChange}
                          sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                          label="Primary home address"
                          //@ts-ignore
                          error={errors?.borrowerAddress?.message}
                        />
                      )}
                    />
                    {borrowerStreet === "My address is not listed" && (
                      <>
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"flex-start"}
                          flexDirection={{ xs: "column", md: "row" }}
                        >
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manual_borrower_street_number"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Number"
                                  placeholder="Street number"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manual_borrower_street_number
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manual_borrower_street_name"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Name"
                                  placeholder="Street name"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manual_borrower_street_name?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box width={{ xs: "100%", md: "50%" }}>
                            <Controller
                              control={control}
                              name="manual_borrower_street_suffix"
                              render={({ field }) => (
                                <CTextField
                                  label="Street Suffix"
                                  placeholder="Ln, Rd, St..."
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manual_borrower_street_suffix
                                      ?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manual_borrower_apt"
                              render={({ field }) => (
                                <CTextField
                                  label="Apt/Suite"
                                  placeholder="Unit #"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={errors?.manual_borrower_apt?.message}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"flex-start"}
                          flexDirection={{ xs: "column", md: "row" }}
                        >
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            ml={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manual_borrower_city"
                              render={({ field }) => (
                                <CTextField
                                  label="City"
                                  placeholder="City"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={errors?.manual_borrower_city?.message}
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            ml={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manual_borrower_state"
                              render={({ field }) => (
                                <CTextField
                                  label="State"
                                  placeholder="VA, FL, TX..."
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={errors?.manual_borrower_state?.message}
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "50%" }}
                            ml={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="manual_borrower_zipcode"
                              render={({ field }) => (
                                <CTextField
                                  label="Zipcode"
                                  placeholder="Zipcode"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={
                                    errors?.manual_borrower_zipcode?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}
                <Controller
                  control={control}
                  name="homeValue"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Home Value"
                      placeholder="$200,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$ "
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.homeValue?.message}
                      value={parseMoney(field.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="currentLoanBalance"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Current Loan Balance"
                      placeholder="$100,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$ "
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.currentLoanBalance?.message}
                      value={parseMoney(field.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="requestedNewLoan"
                  render={({ field }) => (
                    <CNumberField
                      fullWidth
                      label="Requested Loan Amount"
                      placeholder="$65,000"
                      isNumericString
                      thousandSeparator
                      disabled={disabledField}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      prefix="$ "
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.requestedNewLoan?.message}
                      value={parseMoney(field.value)}
                      onChange={(e) => {
                        form.setValue("requestedNewLoan", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="useProceeds"
                  render={({ field }) => (
                    <CTextField
                      select
                      fullWidth
                      label="Use of Proceeds"
                      disabled={disabledField}
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.useProceeds?.message}
                    >
                      <MenuItem value="Home Improvement">
                        Home Improvement
                      </MenuItem>
                      <MenuItem value="Debt Consolidation">
                        Debt Consolidation
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </CTextField>
                  )}
                />
                <Controller
                  control={control}
                  name={"creditScore"}
                  render={({ field }) => (
                    <CTextField
                      select
                      fullWidth
                      label="Credit Score"
                      disabled={disabledField}
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.creditScore?.message}
                    >
                      {creditScoreOptions?.map((option, index) => (
                        <MenuItem
                          key={`${index}${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </CTextField>
                  )}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="annualIncome"
                      render={({ field }) => (
                        <CNumberField
                          fullWidth
                          label="Annual Income"
                          placeholder="$85,000"
                          sx={{ mb: 3 }}
                          isNumericString
                          thousandSeparator
                          disabled={disabledField}
                          allowNegative={false}
                          allowEmptyFormatting={false}
                          prefix="$ "
                          {...field}
                          error={errors?.annualIncome?.message}
                          value={parseMoney(field.value)}
                        />
                      )}
                    />
                  </Box>
                  <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="employmentType"
                      render={({ field }) => (
                        <CTextField
                          select
                          fullWidth
                          label="Employment Type"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.employmentType?.message}
                        >
                          <MenuItem value="Full Time Employed/ W2">
                            Full Time Employed (W2)
                          </MenuItem>
                          <MenuItem value="Self Employed">
                            Self-employed, Fixed income, or other
                          </MenuItem>
                        </CTextField>
                      )}
                    />
                  </Box>
                </Box>
                {isFullEmployed && (
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                  >
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      mr={{ xs: 0, md: 1 }}
                    >
                      <Controller
                        control={control}
                        name="employerNameBorrower"
                        render={({ field }) => (
                          <CTextField
                            label="Borrower’s Employer Name"
                            placeholder={privateLabel?.lenderName}
                            fullWidth
                            autoComplete="off"
                            disabled={disabledField}
                            sx={{ mb: 3 }}
                            {...field}
                            error={errors?.employerNameBorrower?.message}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <Controller
                        control={control}
                        name="startDateBorrower"
                        render={({ field }) => (
                          <CTextField
                            type="date"
                            label="Start Date"
                            InputProps={{
                              inputProps: {
                                min: "1908-02-05",
                                max: moment()
                                  .subtract(0, "year")
                                  .format("YYYY-MM-DD"),
                              },
                            }}
                            placeholder="John"
                            fullWidth
                            autoComplete="off"
                            disabled={disabledField}
                            sx={{ mb: 3 }}
                            {...field}
                            error={errors?.startDateBorrower?.message}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({ field }) => (
                        <CTextField
                          label="Legal First Name"
                          placeholder="John"
                          fullWidth
                          autoComplete="off"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.firstName?.message}
                        />
                      )}
                    />
                  </Box>

                  <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="lastName"
                      render={({ field }) => (
                        <CTextField
                          label="Legal Last Name"
                          placeholder="Doe"
                          fullWidth
                          autoComplete="off"
                          disabled={disabledField}
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.lastName?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>
                {invite && invite.inviteType === "one_use" && (
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                  >
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      mr={{ xs: 0, md: 1 }}
                    >
                      <Controller
                        control={control}
                        name="middleName"
                        render={({ field }) => (
                          <CTextField
                            label="Legal Middle Name"
                            fullWidth
                            autoComplete="off"
                            sx={{ mb: 3 }}
                            {...field}
                            error={errors?.middleName?.message}
                          />
                        )}
                      />
                    </Box>

                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <Controller
                        control={control}
                        name="suffix"
                        render={({ field }) => (
                          <CTextField
                            label="Suffix"
                            placeholder=""
                            fullWidth
                            autoComplete="off"
                            sx={{ mb: 3 }}
                            {...field}
                            error={errors?.suffix?.message}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field }) => (
                        <CNumberField
                          fullWidth
                          autoComplete="off"
                          label="Phone Number"
                          disabled={disabledField}
                          placeholder="+1(123)-123-1234"
                          format={
                            process.env.REACT_APP_ENV === "prod"
                              ? "+1###############"
                              : "+###############"
                          }
                          sx={{ mb: 3 }}
                          allowEmptyFormatting
                          {...field}
                          error={errors?.phoneNumber?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                    <Controller
                      control={control}
                      name="maritalStatus"
                      render={({ field }) => (
                        <CTextField
                          select
                          fullWidth
                          label="Marital Status"
                          sx={{ mb: 3 }}
                          {...field}
                          error={errors?.maritalStatus?.message}
                        >
                          <MenuItem value="Unmarried">Unmarried</MenuItem>
                          <MenuItem value="Married">Married</MenuItem>
                          <MenuItem value="Separated">Separated</MenuItem>
                        </CTextField>
                      )}
                    />
                  </Box>
                </Box>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <CTextField
                      label="Email Address"
                      disabled={disabledField}
                      placeholder="Type in here"
                      fullWidth
                      autoComplete="off"
                      sx={{ mb: 3 }}
                      {...field}
                      error={errors?.email?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <CPasswordField
                      label="Create Password"
                      placeholder="Type in here"
                      fullWidth
                      autoComplete="new-password"
                      helperText={passwordMsgHelper}
                      {...field}
                      error={errors?.password?.message}
                    />
                  )}
                />
                <List
                  disablePadding
                  sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
                >
                  <ListItem
                    disableGutters
                    alignItems="flex-start"
                    sx={{ mt: 1 }}
                  >
                    <Box display="inline" fontFamily="Pro Display Semibold">
                      <Controller
                        control={control}
                        name="hasAcceptTerms"
                        render={({ field }) => (
                          <Checkbox
                            sx={{ pl: 0 }}
                            onClick={() => field.onChange(!field.value)}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Typography
                      mt={0.8}
                      variant="subtitle1"
                      color={theme.palette.text.primary}
                      textAlign={"justify"}
                    >
                      I agree to the{" "}
                      {privateLabel?.isNFTYDoor ? (
                        <Button
                          variant="text"
                          color="primary"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            verticalAlign: "inherit",
                            fontSize: "inherit",
                            padding: 0,
                            minWidth: "auto",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              textDecoration: "none",
                            },
                          }}
                          onClick={() => {
                            form.setOpenedDialog("terms");
                          }}
                        >
                          Terms of Service
                        </Button>
                      ) : (
                        <Link
                          color={theme.palette.primary.main}
                          textAlign={"justify"}
                          sx={{ cursor: "pointer", textDecoration: "none" }}
                          href={privateLabel?.footerPolicy?.privacyTerms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </Link>
                      )}{" "}
                      and{" "}
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy.privacyPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters alignItems="flex-start">
                    <Box display="inline" fontFamily="Pro Display Semibold">
                      <Controller
                        control={control}
                        name={"hasAcceptCertify"}
                        render={({ field }) => (
                          <Checkbox
                            sx={{ pl: 0 }}
                            onClick={() => field.onChange(!field.value)}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Typography
                      mt={0.8}
                      variant="subtitle1"
                      color={theme.palette.text.primary}
                      textAlign={"justify"}
                    >
                      I certify that by checking this box I have read{" "}
                      {privateLabel?.isNFTYDoor ? (
                        <Button
                          variant="text"
                          color="primary"
                          sx={{
                            cursor: "pointer",
                            textWrap: "nowrap",
                            textDecoration: "none",
                            padding: 0,
                            minWidth: "auto",
                            verticalAlign: "inherit",
                            fontSize: "inherit",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              textDecoration: "none",
                            },
                          }}
                          onClick={() => {
                            form.setOpenedDialog("communications");
                          }}
                        >
                          NFTYDoor's Electronic Communications Policy
                        </Button>
                      ) : (
                        <Link
                          color={theme.palette.primary.main}
                          textAlign={"justify"}
                          sx={{ cursor: "pointer", textDecoration: "none" }}
                          href={privateLabel?.footerPolicy?.privacyPolicyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {privateLabel?.lenderName} Electronic Communications
                          Policy
                        </Link>
                      )}
                      , and consent to receive all legally required notices and
                      disclosures and other communications (“Communications”)
                      from{" "}
                      {privateLabel?.isNFTYDoor
                        ? "NFTYDoor LLC "
                        : privateLabel?.lenderName}
                      electronically and not on paper. I also confirm I am able
                      to access, view, retain and print all of the
                      Communications{" "}
                      {privateLabel?.isNFTYDoor
                        ? "NFTYDoor LLC "
                        : privateLabel?.lenderName}
                      provides me in electronic form.
                    </Typography>
                  </ListItem>
                </List>
                <Typography
                  my={"1rem"}
                  color={theme.palette.text.secondary}
                  variant="subtitle2"
                  textAlign={"justify"}
                >
                  By pressing the “I agree” button immediately following this
                  notice, I am providing ‘written instructions’ to{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor"} under
                  the Fair Credit Reporting Act authorizing{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor "} to
                  obtain information from my personal credit profile or other
                  information from Experian. I authorize{" "}
                  {privateLabel ? privateLabel.lenderName : "NFTYDoor "} to
                  obtain such information solely to conduct a pre-qualification
                  for credit and acknowledge this will not affect my credit
                  score.
                </Typography>
                <CButton
                  fullWidth
                  data-cy="iAgree"
                  type="submit"
                  variant="contained"
                  disabled={disableSubmit}
                  sx={{
                    mt: 4,
                    mb: 2,
                    fontSize: 18,
                    textTransform: "inherit",
                  }}
                  loading={
                    form.formState.isSubmitting || user.isGettingLoggedIn
                  }
                >
                  I Agree
                </CButton>
              </form>
            </Box>
          </Box>
        </Fragment>
      </Container>
      <CVerificationDialog
        phoneNumber={form.watch("phoneNumber")}
        open={form.openedDialog === "verify"}
        defaultVerificationType="sms"
        email={form.watch("email")}
        onConfirmed={() => {
          form.setOpenedDialog(undefined);
          user.login(form.watch("email"), form.watch("password"));
        }}
      />
      <CDialog
        icon="warning"
        description={form.emailAlreadyUseOnPL}
        open={form.openedDialog === "EMAIL_IN_USE_ON_PL"}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            user.login(form.watch("email"), form.watch("password"));
            form.setOpenedDialog(undefined);
          }}
        >
          Continue
        </CButton>
      </CDialog>
      <CDialog
        icon="cancel"
        description={
          userAlreadyExists
            ? "User already exists, please login here."
            : form.errorCode
              ? `${form.errorMessage} ${
                  form.errorMessage?.includes("support@nftydoor.com") ||
                  privateLabel
                    ? " "
                    : "Contact us at support@nftydoor.com for any questions."
                }`
              : form.errorMessage
        }
        open={
          form.openedDialog === "error" &&
          form.errorCode !== "BORROWER_IS_ARCHIVED"
        }
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() =>
            userAlreadyExists
              ? navigate("/login", { replace: true })
              : form.setOpenedDialog(undefined)
          }
        >
          {userAlreadyExists ? "Login" : "Accept"}
        </CButton>
        {userAlreadyExists && (
          <CButton
            fullWidth
            variant="outlined"
            onClick={() => form.setOpenedDialog(undefined)}
            sx={{ mt: 2 }}
          >
            Go back
          </CButton>
        )}
      </CDialog>
      <CDialog
        icon="cancel"
        description={form.errorMessage}
        error={form.errorCode}
        open={
          form.openedDialog === "error" &&
          form.errorCode === "BORROWER_IS_ARCHIVED"
        }
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() =>
            userAlreadyExists
              ? navigate("/login", { replace: true })
              : form.setOpenedDialog(undefined)
          }
        >
          Accept
        </CButton>
      </CDialog>
      <ConfirmValuesDialog form={form} />
      <CTermsDialog
        open={form.openedDialog === "terms"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
      <CCommunicationsDialog
        open={form.openedDialog === "communications"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
    </Fragment>
  );
};

export default Form;
