import { useForm, Controller, FieldErrors } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box } from "@mui/system";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import CTextField from "components/CTextField";
import { useEffect } from "react";
import Loan from "typedef/Loan";
import Address from "typedef/Address";
import { Typography } from "@mui/material";
import { ValuesHELOC, ValuesSliderProps } from "../useDefineLoanType";
import statesList from "utils/statesList";
import useUser from "context/UserCustomer/useUserCustomerContext";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";

type UseDefineLoanType = {
  loan: Loan | undefined;
  marks: { value: number }[];
  loading: boolean;
  saveOffer: (
    loanType: string,
    initialDrawAmount?: number,
    demographic?: DemographicsDataChecks,
  ) => Promise<void>;
  isAccepting: boolean;
  errorMessage: string | undefined;
  newValuesSlider: ValuesHELOC | undefined;
  showErrorMessage: boolean;
  initialValuesSliderHELOC: ValuesSliderProps | undefined;
  recalculateOffer: (incomingNewAmount: number | number[]) => Promise<void>;
  setPrimaryHomeAddres: (address: Address | undefined) => void;
  setPrimaryHomeAddresError: (errors: FieldErrors) => void;
  primaryHomeAddress: Address | undefined;
};

type FieldsToValidate = {
  borrowerAddress: boolean;
  manual_borrower_street_number: boolean;
  manual_borrower_street_name: boolean;
  manual_borrower_city: boolean;
  manual_borrower_state: boolean;
  manual_borrower_street_suffix: boolean;
  manual_borrower_zipcode: boolean;
};
interface PrimaryHomeAddressProps {
  useDefineLoanType: UseDefineLoanType;
}

const validationSchema = Yup.object().shape({
  borrowerAddress: Yup.mixed().required("Sub property address is required."),
  manual_borrower_street_number: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("Street number is required")
          .max(6, "The street number must be less than 6 digits.")
          .matches(
            /^\d+$/,
            "The street number must be a number and cannot contain special characters or spaces",
          )
          .trim();
      }
    },
  ),
  manual_borrower_street_name: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("Street name is required")
          .max(45, "The street name must be less than 45 letters or numbers.");
      }
    },
  ),
  manual_borrower_street_suffix: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("Street suffix is required")
          .max(6, "The street suffix must be less than 6 letters.")
          .matches(
            /^[a-zA-Z]+$/,
            "The street suffix must only contain letters and cannot contain numbers or special characters",
          );
      }
    },
  ),
  manual_borrower_apt: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema.optional();
      }
    },
  ),
  manual_borrower_city: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("City is required")
          .max(45, "The city must be less than 45 letters.")
          .matches(/^[A-Za-z\s]+$/, "City can only contain letters and spaces");
      }
    },
  ),
  manual_borrower_state: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("State is required")
          .test("length", "State must be abbreviated", (value: string) => {
            if (!value) return true;
            else return value?.length === 2;
          })
          .test("list", "State is not valid", (value: string) => {
            if (!value) return true;
            else return statesList.includes(value);
          });
      }
    },
  ),
  manual_borrower_zipcode: Yup.string().when(
    ["borrowerAddress"],
    (watchBorrowerAddress, schema) => {
      if (watchBorrowerAddress?.street_line === "My address is not listed") {
        return schema
          .required("Zipcode is required")
          .matches(/^\d+$/, "Zipcode can only contain numbers")
          .test("lengthZ", "Zipcode must be 5 digits long", (value: string) => {
            if (!value) return true;
            else return value?.length === 5;
          });
      }
    },
  ),
});

const PrimaryHomeAddress = ({ useDefineLoanType }: PrimaryHomeAddressProps) => {
  const { user } = useUser();

  const addressComponentsFromRegister = user?.address?.components;
  const streetLine = `${addressComponentsFromRegister?.primary_number} ${addressComponentsFromRegister?.street_name} ${addressComponentsFromRegister?.street_suffix}`;
  const address = {
    city: addressComponentsFromRegister?.city_name,
    entries: 0,
    secondary: "",
    source: "postal",
    state: addressComponentsFromRegister?.state_abbreviation,
    street_line: streetLine,
    zipcode: addressComponentsFromRegister?.zipcode,
  };
  const defautlBorrowerAddress = addressComponentsFromRegister ? address : null;
  const valuesBorrowerAddress = useDefineLoanType.primaryHomeAddress;

  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      borrowerAddress:
        valuesBorrowerAddress ?? (defautlBorrowerAddress as unknown as Address),
      manual_borrower_street_number: "",
      manual_borrower_street_name: "",
      manual_borrower_street_suffix: "",
      manual_borrower_apt: "",
      manual_borrower_city: "",
      manual_borrower_state: "",
      manual_borrower_zipcode: "",
    },
  });

  const watchBorrowerAddress = watch("borrowerAddress");
  const manual_borrower_street_number = watch("manual_borrower_street_number");
  const manual_borrower_street_name = watch("manual_borrower_street_name");
  const manual_borrower_city = watch("manual_borrower_city");
  const manual_borrower_state = watch("manual_borrower_state");
  const manual_borrower_zipcode = watch("manual_borrower_zipcode");
  const manual_borrower_street_suffix = watch("manual_borrower_street_suffix");

  const manualAddress = {
    delivery_line_1: `${manual_borrower_street_number?.trim()} ${manual_borrower_street_name?.trim()} ${manual_borrower_street_suffix?.trim()}`,
    components: {
      street_name: manual_borrower_street_name?.trim(),
      city_name: manual_borrower_city?.trim(),
      state_abbreviation: manual_borrower_state?.trim(),
      zipcode: manual_borrower_zipcode?.trim(),
      primary_number: manual_borrower_street_number?.trim(),
      street_suffix: manual_borrower_street_suffix?.trim(),
    },
  };

  const primaryHomeData =
    watchBorrowerAddress?.street_line === "My address is not listed"
      ? manualAddress
      : watchBorrowerAddress;

  useEffect(() => {
    useDefineLoanType.setPrimaryHomeAddres(
      primaryHomeData as unknown as Address,
    );
    useDefineLoanType.setPrimaryHomeAddresError(errors);
  }, [primaryHomeData, useDefineLoanType, errors]);

  useEffect(() => {
    const fieldsToValidate: FieldsToValidate = {
      borrowerAddress:
        (watchBorrowerAddress &&
          watchBorrowerAddress.street_line !== "My address is not listed") ||
        watchBorrowerAddress !== null,
      manual_borrower_street_number:
        manual_borrower_street_number.trim() !== "",
      manual_borrower_street_name: manual_borrower_street_name.trim() !== "",
      manual_borrower_city: manual_borrower_city.trim() !== "",
      manual_borrower_state: manual_borrower_state.trim() !== "",
      manual_borrower_street_suffix:
        manual_borrower_street_suffix.trim() !== "",
      manual_borrower_zipcode: manual_borrower_zipcode.trim() !== "",
    };

    Object.entries(fieldsToValidate).forEach(([field, condition]) => {
      if (condition) {
        trigger(field as keyof FieldsToValidate);
      }
    });
  }, [
    watchBorrowerAddress,
    manual_borrower_street_number,
    manual_borrower_street_name,
    manual_borrower_city,
    manual_borrower_state,
    manual_borrower_street_suffix,
    manual_borrower_zipcode,
    trigger,
  ]);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} width="100%">
        <Typography
          fontSize="22px"
          maxWidth="220px"
          flexShrink={0}
          marginBottom={4}
        >
          Primary home address
        </Typography>
        <Box flex="1" width="0">
          <Controller
            name="borrowerAddress"
            control={control}
            render={({ field }) => (
              <CAddressAutocomplete
                {...field}
                name="propertyAddressAutocomplete"
                onChange={undefined}
                placeholder="Please enter the address of the home you currently live in."
                address={field.value}
                setAddress={field.onChange}
                sx={{ mb: 3, mt: { xs: 2, md: 0 } }}
                error={errors?.borrowerAddress?.message}
              />
            )}
          />
        </Box>
      </Box>
      {watchBorrowerAddress?.street_line === "My address is not listed" && (
        <>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box width={{ xs: "100%", md: "50%" }}>
              <Controller
                control={control}
                name="manual_borrower_street_number"
                render={({ field }) => (
                  <CTextField
                    label="Street Number"
                    placeholder="Street number"
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_street_number?.message}
                  />
                )}
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Controller
                control={control}
                name="manual_borrower_street_name"
                render={({ field }) => (
                  <CTextField
                    label="Street Name"
                    placeholder="Street name"
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_street_name?.message}
                  />
                )}
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Controller
                control={control}
                name="manual_borrower_street_suffix"
                render={({ field }) => (
                  <CTextField
                    label="Street Suffix"
                    placeholder="Ln, Rd, St..."
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_street_suffix?.message}
                  />
                )}
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
              <Controller
                control={control}
                name="manual_borrower_apt"
                render={({ field }) => (
                  <CTextField
                    label="Apt/Suite"
                    placeholder="Unit #"
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_apt?.message}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
              <Controller
                control={control}
                name="manual_borrower_city"
                render={({ field }) => (
                  <CTextField
                    label="City"
                    placeholder="City"
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_city?.message}
                  />
                )}
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
              <Controller
                control={control}
                name="manual_borrower_state"
                render={({ field }) => (
                  <CTextField
                    label="State"
                    placeholder="VA, FL, TX..."
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_state?.message}
                  />
                )}
              />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
              <Controller
                control={control}
                name="manual_borrower_zipcode"
                render={({ field }) => (
                  <CTextField
                    label="Zipcode"
                    placeholder="Zipcode"
                    fullWidth
                    autoComplete="off"
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.manual_borrower_zipcode?.message}
                  />
                )}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PrimaryHomeAddress;
