export const styles = {
  modalStyle: {
    "& .MuiPaper-root": {
      borderRadius: 10,
      overflow: "clip",
      textAlign: "center",
      alignItems: "center",
      padding: "2rem",
      justifyContent: "center",
      "@media only screen and (max-width: 600px)": {
        padding: "1rem",
      },
      "@media only screen and (min-width: 900px)": {
        width: "100%",
      },
      maxWidth: "700px",
    },
  },
  containerCounter: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
};
