import Address from "typedef/Address";
import { useParams } from "react-router-dom";
import { RegisterFormInputs } from "./useRegisterForm";
import { useInvite } from "context/Invite/useInviteContext";

const useParseToSubmit = () => {
  const invite = useInvite();
  const params = useParams();
  const sourceId = params["sourceId"];

  return (data: RegisterFormInputs) => {
    const totalAnnualIncomeBorrower = data.totalAnnualIncomeBorrower;
    const estimatedHomeValue = data.estimatedHomeValue;
    const requestedLoanAmount = data.requestedLoanAmount;
    const currentLoanBalance = data.currentLoanBalance;
    const phoneBorrower = data.phoneBorrower.replace(/ /g, "");
    const creditScoreRange = data.creditScoreRange;
    const { street_line, ...restOfAddress } = data.propertyAddress;
    const propertyAddress = {
      street: street_line,
      ...restOfAddress,
    };
    let borrowerAddress: (Address & { street?: string }) | undefined;
    if (data.borrowerAddress) {
      const { ...propsBorrowerAddress } = data.borrowerAddress;

      borrowerAddress = {
        street: propsBorrowerAddress?.street_line,
        ...propsBorrowerAddress,
      };
    }

    return {
      PLMasterLoanOfficer: data?.PLMasterLoanOfficer,
      pricingEngineId: data?.pricingEngineId,
      propertyAddress,
      borrowerAddress,
      confirmEmailAddressBorrower: data.confirmEmailAddressBorrower?.trim(),
      hasAcceptTerms: true,
      agreeNotifications: true,
      legalLastNameBorrower: data.legalLastNameBorrower?.trim(),
      phoneBorrower,
      legalFirstNameBorrower: data.legalFirstNameBorrower?.trim(),
      useOfProceeds: { label: data.useOfProceeds, value: data.useOfProceeds },
      occupancyType: { label: data.occupancyType, value: data.occupancyType },
      estimatedHomeValue: estimatedHomeValue,
      employmentTypeBorrower: {
        label: data.employmentTypeBorrower,
        value: data.employmentTypeBorrower,
      },
      maritalStatusBorrower: {
        label: data.maritalStatusBorrower,
        value: data.maritalStatusBorrower,
      },
      totalAnnualIncomeBorrower: totalAnnualIncomeBorrower,
      requestedLoanAmount: requestedLoanAmount,
      currentLoanBalance: currentLoanBalance,
      creditScoreRange: { label: creditScoreRange, value: creditScoreRange },
      inviteCode: invite?.code,
      sourceId: sourceId,
      completedByBorrower: true,
      legalSuffixBorrower: data.legalSuffixBorrower?.trim(),
      employerNameBorrower: data.employerNameBorrower?.trim(),
      startDateBorrower: data.startDateBorrower?.trim(),
      loanOfficerId: invite?.loanOfficerId,
      brokerId: invite?.broker?.id,
      brokerSk: invite?.broker?.sk,
      password: data.password,
      customAddress:
        data.propertyAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manualAddress.city?.trim(),
              secondary: data?.manualAddress?.secondary?.trim(),
              state: data?.manualAddress?.state?.toLocaleUpperCase()?.trim(),
              street_name: data?.manualAddress?.street_line?.trim(),
              street_number: data?.manualAddress?.street_number?.trim(),
              zipcode: data?.manualAddress?.zipcode?.trim(),
              street_suffix: data?.manualAddress?.street_suffix?.trim(),
            }
          : undefined,
      manualPropertyAddress:
        data.propertyAddress?.street_line === "My address is not listed",
      manualBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed",
      customBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manualBorrowerAddress?.city?.trim(),
              secondary: data?.manualBorrowerAddress?.secondary?.trim(),
              state: data?.manualBorrowerAddress?.state
                ?.toLocaleUpperCase()
                ?.trim(),
              street_name: data?.manualBorrowerAddress?.street_line?.trim(),
              street_number: data?.manualBorrowerAddress?.street_number?.trim(),
              zipcode: data?.manualBorrowerAddress?.zipcode?.trim(),
              street_suffix: data?.manualBorrowerAddress?.street_suffix?.trim(),
            }
          : undefined,
    };
  };
};

export default useParseToSubmit;
