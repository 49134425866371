import DrawHELOC from "./DrawHELOC";
import Documents from "./Documents";
import AddressBox from "./AddressBox";
import LoanBalance from "./LoanBalance";
import Typography from "@mui/material/Typography";
import HomeMonitorLoader from "./HomeMonitorLoader";
import QuestionsBox from "components/CQuestionChat";
import ScheduledPayments from "./ScheduledPayments";
import { HomeMonitorContextProvider } from "./useHomeMonitorContext";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import useLoan from "./useLoan";
import SLSInfoBox from "./SLSInfoBox";
import ArchivedBox from "./ArchivedBox";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import PaymentHistoryBox from "./PaymentsHistory";

const HomeMonitor: React.FC = () => {
  const theme = useTheme();
  const { loan, loading } = useLoan();
  const isServicerNFTYDoor = loan?.servicer === "NFTYDoor";
  const isArchived = loan?.borrowerFlags?.archiveType;
  const { privateLabel } = usePrivateLabel();
  const isNfty = privateLabel?.isNFTYDoor;
  const isPrivateLabel = privateLabel !== undefined;

  return (
    <HomeMonitorContextProvider>
      <HomeMonitorLoader />
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2.5rem", md: "4rem" }}
          >
            Home Monitor
          </Typography>
        </Box>
        {!loading && (
          <Box>
            <AddressBox />
            <PaymentHistoryBox />
            {!isServicerNFTYDoor && <SLSInfoBox />}
            {isArchived && <ArchivedBox />}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && isNfty && (
              <DrawHELOC />
            )}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && (
              <ScheduledPayments />
            )}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && (
              <LoanBalance />
            )}
            <Documents />
            {!isArchived && isServicerNFTYDoor && <QuestionsBox />}
          </Box>
        )}
      </Container>
    </HomeMonitorContextProvider>
  );
};

export default HomeMonitor;
